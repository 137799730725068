import { Button, useDisclosure } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { SimpleTaskFragment } from "@src/__generated__/graphql";
import {
  SqlOperator,
  TaskSelectOptionsDocument,
  TaskSelectOptionsQuery,
  TaskSelectOptionsQueryVariables,
  TaskSystemStatusEnum,
  TaskWhereColumn,
} from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import {
  LargeSelect,
  LargeSelectProps,
  LargeSelectRef,
} from "@src/components/ui-kit/Select/LargeSelect";
import { client } from "@src/services/client";
import { TaskFormModalOpenOptions } from "@src/stores/TaskFormModalStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { useStore } from "@src/utils/hooks";
import { isTrackingEnabled } from "@src/utils/time-tracking";
import { MakeOptional } from "@src/utils/types";
import { wait } from "@src/utils/wait";
import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";

export type TaskSelectProps = Omit<
  LargeSelectProps,
  "loadOptions" | "onChange"
> & {
  onChange?: (
    value: TaskOption["value"][] | TaskOption["value"],
    data: Partial<TaskOption>[] | Partial<TaskOption>,
  ) => void;
  projectFilter?: string[];
  budgetItemFilter?: string[];
  taskModalOptions?: TaskFormModalOpenOptions;
  disableDoneTasks?: boolean;
  hideNonTrackableTasks?: boolean;
  withCreateTask?: boolean;
  hideNonTrackableTasksForDate?: Date;
  parentTasksOnly?: boolean;
};

type TaskSelectOption = NonNullable<TaskSelectOptionsQuery["tasks"]>["data"][0];

export interface TaskOption {
  value: TaskSelectOption["id"];
  label: TaskSelectOption["name"];
  projectId:
    | NonNullable<TaskSelectOption["ourWorkBudgetItem"]>["project"]["id"]
    | undefined;
  budgetItemId:
    | NonNullable<TaskSelectOption["ourWorkBudgetItem"]>["id"]
    | undefined;
  billable: TaskSelectOption["billable"];
  timeTrackingSettings:
    | NonNullable<TaskSelectOption["ourWorkBudgetItem"]>["timeTrackingSettings"]
    | undefined;
}

export const TaskSelect: FunctionComponent<TaskSelectProps> = observer(
  function TaskSelect({
    onChange,
    projectFilter,
    budgetItemFilter,
    taskModalOptions,
    disableDoneTasks,
    hideNonTrackableTasks,
    hideNonTrackableTasksForDate,
    withCreateTask = true,
    isMulti,
    defaultOptions,
    parentTasksOnly = false,
    ...props
  }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { taskFormModalStore } = useStore();
    const prevBudgetItemFilter = usePrevious(budgetItemFilter);
    const prevProjectFilter = usePrevious(projectFilter);
    const [options, setOptions] = useState<TaskOption[]>([]);
    const selectRef = useRef<LargeSelectRef>(null);
    const filters = new Filters<TaskWhereColumn>([
      new Filter({
        column: TaskWhereColumn.ProjectId,
        operator: SqlOperator.In,
        options: [],
        value: projectFilter,
      }),
      new Filter({
        column: TaskWhereColumn.OurWorkBudgetItemId,
        operator: SqlOperator.In,
        options: [],
        value: budgetItemFilter,
      }),
      new Filter({
        column: TaskWhereColumn.HideDone,
        operator: SqlOperator.Eq,
        options: [],
        value: "true",
      }),
      new Filter({
        column: TaskWhereColumn.OnlyParent,
        options: [],
        operator: SqlOperator.Eq,
        value: `${parentTasksOnly}`,
      }),
    ]);

    useEffect(() => {
      if (selectRef.current && props.autoFocus) {
        onOpen();
        selectRef.current.focus();
      }
    }, []);

    const _onChange = (value: TaskOption["value"] | TaskOption["value"][]) => {
      const getOptionData = (optionValue: TaskOption["value"]) => {
        const currentOption = options.find((o) => o.value === optionValue);
        return {
          value: currentOption?.value,
          label: currentOption?.label,
          projectId: currentOption?.projectId,
          budgetItemId: currentOption?.budgetItemId,
          billable: currentOption?.billable,
          timeTrackingSettings: currentOption?.timeTrackingSettings,
        };
      };

      if (isMulti) {
        const values = value as TaskOption["value"][];
        const data = values.map(getOptionData);
        onChange?.(values, data);
      } else {
        const singleValue = value as TaskOption["value"];
        const data = getOptionData(singleValue);
        onChange?.(singleValue, data);
      }
    };

    const loadOptions = async (
      page: number,
      perPage: number,
      searchPhrase: string,
    ) => {
      return await client
        .query<TaskSelectOptionsQuery, TaskSelectOptionsQueryVariables>(
          TaskSelectOptionsDocument,
          {
            page,
            first: perPage,
            filters: {
              search: searchPhrase,
              where: filters.asWhereParam,
            },
          },
        )
        .toPromise()
        .then((res) => {
          const data: TaskOption[] = (res.data?.tasks?.data || [])
            .filter((task) => {
              if (!hideNonTrackableTasks) return true;
              if (!task.ourWorkBudgetItem?.timeTrackingSettings) return false;

              return isTrackingEnabled(
                task.ourWorkBudgetItem.timeTrackingSettings,
                hideNonTrackableTasksForDate,
              );
            })
            .filter((task) => {
              if (!defaultOptions) return true;
              if (!Array.isArray(defaultOptions)) return true;
              return !defaultOptions
                .map(({ value }) => value)
                .includes(task.id);
            })
            .map((c) => ({
              label: generateTaskOptionLabel(c),
              projectId: c.ourWorkBudgetItem?.project.id,
              budgetItemId: c.ourWorkBudgetItem?.id,
              value: c.id,
              disabled:
                disableDoneTasks &&
                c.status.system_status === TaskSystemStatusEnum.Done,
              billable: c.billable,
              timeTrackingSettings: c.ourWorkBudgetItem?.timeTrackingSettings,
            }));

          setOptions((prev) => {
            const dataCount = data.length;
            for (let i = 0; i < dataCount; i++) {
              prev.push(data[i]);
            }
            return prev;
          });

          return {
            data,
            paginatorInfo: res.data?.tasks?.paginatorInfo,
          };
        });
    };

    useEffect(() => {
      if (isEqual(prevProjectFilter, projectFilter)) return;

      selectRef.current?.clearOptions();
      setOptions([]);
    }, [projectFilter]);

    useEffect(() => {
      if (isEqual(prevBudgetItemFilter, budgetItemFilter)) return;

      selectRef.current?.clearOptions();
      setOptions([]);
    }, [budgetItemFilter]);

    useEffect(() => {
      if (!isOpen) return;

      selectRef.current?.focus();
    }, [options.length, isOpen, selectRef.current]);

    const onSubmit = async (task: SimpleTaskFragment) => {
      const newOption: TaskOption = {
        value: task.id,
        label: task.ourWorkBudgetItem
          ? `${task.ourWorkBudgetItem.project.code} / ${task.ourWorkBudgetItem.title} / ${task.name}`
          : task.name,
        projectId: task.ourWorkBudgetItem?.project.id,
        budgetItemId: task.ourWorkBudgetItem?.id,
        billable: task.billable,
        timeTrackingSettings: task.ourWorkBudgetItem
          ? {
              tracking_enabled_from: task.ourWorkBudgetItem.timeTrackingSettings
                .tracking_enabled_from
                ? new Date(
                    task.ourWorkBudgetItem.timeTrackingSettings.tracking_enabled_from,
                  )
                : undefined,
              tracking_enabled_to: task.ourWorkBudgetItem.timeTrackingSettings
                .tracking_enabled_to
                ? new Date(
                    task.ourWorkBudgetItem.timeTrackingSettings.tracking_enabled_to,
                  )
                : undefined,
              tracking_enabled:
                task.ourWorkBudgetItem.timeTrackingSettings.tracking_enabled,
            }
          : undefined,
      };
      setOptions((prevOptions) => {
        prevOptions.unshift(newOption);
        return prevOptions;
      });
      await wait(100);
      _onChange(task.id);
      await wait(100);
      selectRef.current?.setValue(newOption, "select-option");
    };

    return (
      <LargeSelect
        ref={selectRef}
        topExtraContent={
          withCreateTask && (
            <Button
              p="1"
              leftIcon={<Icon name="plus" />}
              onClick={() =>
                taskFormModalStore.modalState.onOpen({
                  onSubmit,
                  ...taskModalOptions,
                  hideCreateAllocationsCheckbox: true,
                })
              }
              variant="link"
            >
              <Trans>Create new task</Trans>
            </Button>
          )
        }
        loadOptions={loadOptions}
        placeholder={t`Select task`}
        isClearable
        onChange={_onChange}
        menuIsOpen={props.autoFocus ? isOpen : undefined}
        onMenuOpen={props.autoFocus ? onOpen : undefined}
        onMenuClose={props.autoFocus ? onClose : undefined}
        isMulti={isMulti}
        // INFO: NEVER DELETE
        ignoreFilterOptions
        defaultOptions={defaultOptions}
        {...props}
      />
    );
  },
);

export type OurWorkBudgetItemForLabel = MakeOptional<
  Pick<
    NonNullable<
      NonNullable<
        TaskSelectOptionsQuery["tasks"]
      >["data"][0]["ourWorkBudgetItem"]
    >,
    "title" | "project"
  >,
  "title"
>;
export function generateTaskOptionLabel({
  ourWorkBudgetItem,
  status,
  name,
}: Pick<
  NonNullable<TaskSelectOptionsQuery["tasks"]>["data"][0],
  "status" | "name"
> & {
  ourWorkBudgetItem?: OurWorkBudgetItemForLabel | null;
}) {
  return ourWorkBudgetItem
    ? `${ourWorkBudgetItem.project.code} - ${
        ourWorkBudgetItem.project.title
      } | ${name} ${
        status.system_status === TaskSystemStatusEnum.Done
          ? `(${status.name})`
          : ""
      }${"title" in ourWorkBudgetItem ? " (" + ourWorkBudgetItem.title + ")" : ""}`
    : `${name} ${
        status.system_status === TaskSystemStatusEnum.Done
          ? `(${status.name})`
          : ""
      }`;
}
