import { t } from "@lingui/macro";
import {
  SimpleTaskForClientFragment,
  SimpleTaskFragment,
  useCheckTaskRevisionQuery,
  useGetProjectOptionsLazyQuery,
  useUpdateScopedTaskMutation,
  useUpdateTaskMutation,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { ModalTaskForm } from "@src/components/widgets/Modals/ModalTask";
import { useAutocompletePositionsFromOurWorkBudgetItem } from "@src/components/widgets/Modals/ModalTask/useAutocompletePositionsFromOurWorkBudgetItem";
import { trackEvent } from "@src/services/amplitude";
import { appStore } from "@src/stores/AppStore";
import { useStore } from "@src/utils/hooks";
import mapToOptions from "@src/utils/map-to-options";
import { FormMode } from "@src/utils/types";
import { generateTaskOptionLabel } from "@src/widgets/TaskSelect/TaskSelect";
import { omit } from "lodash";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { TASK_REVISION_CHECK_INTERVAL } from "../ModalCommunication/ModalCommunication";
import { TaskModel } from "../ModalCommunication/models";
import { ModalTask, ModalTaskProps } from "./ModalTask";

type ModalTaskEditProps = Omit<ModalTaskProps, "onSubmit" | "mode" | "form"> & {
  taskId: TaskModel["id"];
  onUpdate: (data: SimpleTaskFragment | SimpleTaskForClientFragment) => void;
  mode?: FormMode;
};

export const ModalTaskEdit = observer(function ModalTaskEdit({
  onUpdate,
  taskId,
  mode = FormMode.EDIT,
  ...props
}: ModalTaskEditProps) {
  const { UIStore, authStore } = useStore();
  const { form } = useLocalObservable(() => ({
    form: new ModalTaskForm(appStore),
  }));
  const [alreadyEdited, setAlreadyEdited] = useState(false);
  const [updateTask, { loading: updateLoading }] = useUpdateTaskMutation();
  const [updateScopedTask, { loading: scopedLoading }] =
    useUpdateScopedTaskMutation();
  const [projectOptions, setProjectOptions] = useState<IOption[]>([]);
  const [taskOptions, setTaskOptions] = useState<IOption[]>([]);

  const { refetch: checkTaskRevision, data: taskRevisionData } =
    useCheckTaskRevisionQuery({
      skip: true,
      variables: { id: taskId },
    });

  const [fetchProjectOptions] = useGetProjectOptionsLazyQuery({
    onCompleted(data) {
      if (data.projectsSimpleMap) {
        setProjectOptions(mapToOptions.projects(data.projectsSimpleMap));
      }
    },
  });

  const { autocompletePositions } =
    useAutocompletePositionsFromOurWorkBudgetItem(form);

  useEffect(() => {
    if (!props.isOpen) return;
    form.fetchTask(taskId);
    fetchProjectOptions();
  }, [props.isOpen]);

  useEffect(() => {
    if (!form.task) {
      setTaskOptions([]);
      return;
    }
    if (!form.task.positions.length) {
      if (!form.task.ourWorkBudgetItem) return;
      autocompletePositions({
        variables: {
          our_work_budget_item_id: form.task.ourWorkBudgetItem.id,
        },
      });
    }
    if (!form.task.parent) return;
    setTaskOptions([
      {
        value: form.task.parent.id,
        label: generateTaskOptionLabel(form.task.parent),
      },
    ]);
  }, [form.task]);

  useEffect(() => {
    if (!form.task?.id) return;
    if (props.isOpen) {
      checkTaskRevision({ id: form.task.id });
    }

    const interval = setInterval(() => {
      if (!props.isOpen) return;
      checkTaskRevision({ id: form.task?.id });
    }, TASK_REVISION_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [form.task?.id, props.isOpen]);

  useEffect(() => {
    if (!form.task?.revision || !taskRevisionData?.task?.revision) return;
    setAlreadyEdited(form.task.revision !== taskRevisionData.task.revision);
  }, [taskRevisionData, form.task?.revision]);

  const onSubmitWrapper = async (hasError: boolean) => {
    if (hasError) return false;
    const input = form.serialize();
    if (authStore.isInternalUser) {
      const { data } = await updateTask({
        variables: {
          input: { id: taskId, ...omit(input, "files"), addFiles: input.files },
          propagate_billable:
            (mode === FormMode.EDIT || mode === FormMode.MOVE) &&
            form.billabilityChanged
              ? form.propagate_billable.$
              : false,
        },
      });

      if (!data?.updateTask) return false;

      onUpdate(data.updateTask);
      trackEvent("task", "Updated task");
      UIStore.toast({ title: t`Updated`, status: "success" });
      return true;
    } else {
      const { data } = await updateScopedTask({
        variables: {
          input: {
            id: taskId,
            ...omit(input, "files", "deadline"),
            addFiles: input.files,
          },
        },
      });

      if (!data?.updateScopedTask) return false;

      onUpdate(data.updateScopedTask);
      trackEvent("task", "Updated task");
      UIStore.toast({ title: t`Updated`, status: "success" });
      return true;
    }
  };

  return (
    <ModalTask
      form={form}
      mode={mode}
      taskId={taskId}
      projectId={form.projectId.$}
      projectOptions={projectOptions}
      onSubmitLoading={updateLoading || scopedLoading}
      onSubmit={onSubmitWrapper}
      defaultTaskOptions={taskOptions}
      attachments={form.task?.files}
      loading={form.isTaskFetching}
      alreadyEdited={alreadyEdited}
      refetchTask={() => {
        form.fetchTask(taskId);
      }}
      {...props}
    />
  );
});
