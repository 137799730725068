import {
  Box,
  Button,
  Collapse,
  Grid,
  HStack,
  IconButton,
  StackProps,
  StyleProps,
  SystemStyleObject,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import {
  InternalTaskType,
  ScopedTaskType,
} from "@src/components/modules/resource-planning/tasks/listing/TasksListingStore";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { can } from "@src/utils/components/permissions";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Icon } from "../Icon";
import { TaskStatusBadge } from "../TaskStatusBadge";
import { UncollapseOnHover } from "../UncollapseOnHover";
import { TaskCommentsCount } from "./TaskItemComponents";

export type TaskItemBaseProps<TTask extends InternalTaskType | ScopedTaskType> =
  {
    task: TTask;
    title: string;
    titleOpacity?: StyleProps["opacity"];
    wrapperStyle?: SystemStyleObject;
    onClick?: (task: TTask) => void;
    content: (task: TTask) => React.ReactNode;
    extraContent?: (task: TTask) => React.ReactNode;
    subtasksVisible?: boolean;
    subtasksGap?: StackProps["mb"];
    showSubtasksToggle?: boolean;
    onCreateSubtask?: (subtask: TTask) => void;
    isSubtask?: boolean;
  };

export const TaskItemBase = observer(function TaskItemBase<
  TTask extends InternalTaskType | ScopedTaskType,
>({
  task,
  wrapperStyle,
  onClick,
  content,
  extraContent,
  title,
  titleOpacity,
  subtasksVisible,
  subtasksGap,
  showSubtasksToggle = true,
  isSubtask = false,
  onCreateSubtask,
}: TaskItemBaseProps<TTask>) {
  const [isOpen, setIsOpen] = useState(subtasksVisible);
  const hasSubtasks = Array.isArray(task.subtasks) && task.subtasks.length > 0;

  useEffect(() => {
    setIsOpen(subtasksVisible);
  }, [subtasksVisible]);

  if (!task) return null;

  return (
    <WrapComponent
      with={(children) => (
        <VStack gap="0" w="full" mb={isOpen ? undefined : subtasksGap}>
          {children}
          <Box w="full" pl="8">
            <Collapse animateOpacity in={isOpen}>
              {task.subtasks?.map((subtask) => (
                <Box
                  key={subtask.id}
                  pb={subtasksGap}
                  _first={{
                    pt: subtasksGap,
                  }}
                >
                  <TaskItemBaseContent
                    task={subtask}
                    wrapperStyle={wrapperStyle}
                    onClick={(task) => onClick?.(task as TTask)}
                    content={(task) => content(task as TTask)}
                    title={subtask.name}
                    titleOpacity={titleOpacity}
                    extraContent={(task) => extraContent?.(task as TTask)}
                    isSubtask
                  />
                </Box>
              ))}
            </Collapse>
          </Box>
        </VStack>
      )}
      if={hasSubtasks}
    >
      <TaskItemBaseContent
        showSubtasksToggle={showSubtasksToggle}
        task={task}
        wrapperStyle={wrapperStyle}
        onClick={(task) => onClick?.(task as TTask)}
        onClickShowSubtasks={() => {
          setIsOpen((prev) => !prev);
        }}
        content={(task) => content(task as TTask)}
        title={title}
        titleOpacity={titleOpacity}
        extraContent={(task) => extraContent?.(task as TTask)}
        areSubtasksOpen={isOpen}
        isSubtask={isSubtask}
        onCreateSubtask={(subtask) => {
          onCreateSubtask?.(subtask as TTask);
        }}
      />
    </WrapComponent>
  );
});

type TaskItemBaseContentProps = TaskItemBaseProps<
  InternalTaskType | ScopedTaskType
> & {
  isSubtask?: boolean;
  onClickShowSubtasks?: () => void;
  areSubtasksOpen?: boolean;
};
const TaskItemBaseContent = observer(function TaskItemListingContent({
  task,
  wrapperStyle,
  title,
  titleOpacity,
  content,
  onClick,
  onClickShowSubtasks,
  extraContent,
  isSubtask,
  areSubtasksOpen,
  showSubtasksToggle,
  onCreateSubtask,
}: TaskItemBaseContentProps) {
  const { UIStore, authStore, taskFormModalStore } = useStore();
  const { onCopy } = useClipboard(
    `${location.origin}/notifications?${TASK_ID_QUERY_KEY}=${task?.id ?? ""}`,
  );

  const handleCopyId = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onCopy();
    UIStore.toast({
      title: t`Link copied to clipboard`,
      status: "info",
      isClosable: true,
      duration: 3000,
    });
  };

  const badgeWidth = wrapperStyle?.gridTemplateColumns
    ? Object.fromEntries(
        Object.entries(wrapperStyle.gridTemplateColumns).map(([key, value]) => [
          key,
          value.split(" ")[0],
        ]),
      )
    : "9rem";

  const handleCreateSubtask = () => {
    const project = task?.ourWorkBudgetItem?.project;
    const budgetItemId =
      task.ourWorkBudgetItem && "id" in task.ourWorkBudgetItem
        ? task.ourWorkBudgetItem.id
        : undefined;
    taskFormModalStore.modalState.onOpen({
      billable: "billable" in task ? task.billable : false,
      projectId: project?.id,
      budgetItemId,
      templateGroupId: task.task_template_group_id ?? undefined,
      isTemplate: task.is_template,
      parentTask: task.ourWorkBudgetItem
        ? {
            id: task.id,
            name: task.name,
            status: task.status,
            ourWorkBudgetItem: {
              project: task.ourWorkBudgetItem.project,
              title:
                "title" in task.ourWorkBudgetItem
                  ? task.ourWorkBudgetItem.title
                  : "",
            },
          }
        : task,
      onSubmit: (task) => {
        onCreateSubtask?.(task);
      },
    });
  };

  return (
    <Grid
      sx={wrapperStyle}
      alignItems="center"
      gap="4"
      overflow="hidden"
      w="full"
      h="42px"
      px="2.5"
      py="1.5"
      fontWeight="medium"
      bg="white"
      borderBottomWidth="thin"
      borderBottomColor="gray.200"
      shadow="none"
      cursor="pointer"
      data-component-name="create-subtask-button"
      onClick={() => onClick?.(task)}
      role="group"
      rounded="md"
    >
      <TaskStatusBadge
        badgeWidth={badgeWidth}
        background_color={task.status.background_color}
        name={task.status.name}
        foreground_color={task.status.foreground_color}
      />

      <Tooltip label={t`Copy link to task`}>
        <Button onClick={handleCopyId} size="sm" variant="link">
          {task.id}
        </Button>
      </Tooltip>

      <HStack spacing="2">
        <Tooltip label={title}>
          <Text
            color="inherit"
            fontWeight="medium"
            opacity={titleOpacity}
            textOverflow="ellipsis"
            noOfLines={1}
          >
            {title}
          </Text>
        </Tooltip>
        {"billable" in task && task.billable === false && (
          <Tooltip label={t`Non-billable`} shouldWrapChildren>
            <Icon name="non-billable" />
          </Tooltip>
        )}
        {!isSubtask && (
          <UncollapseOnHover
            dataComponentName="create-subtask-button"
            width={can("task_create_all") ? "24" : "0"}
            hiddenComponent={
              !isSubtask && can("task_create_all") ? (
                <Button
                  fontWeight="medium"
                  _hover={{ bgColor: "unset", color: "purple.700" }}
                  _groupHover={{ visibility: "visible" }}
                  visibility="hidden"
                  colorScheme="purple"
                  leftIcon={<Icon name="plus" />}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCreateSubtask();
                  }}
                  size="xs"
                  variant="outline"
                >
                  <Trans>Subtask</Trans>
                </Button>
              ) : (
                <Box />
              )
            }
          >
            {showSubtasksToggle &&
              !isSubtask &&
              task.subtasks &&
              task.subtasks.length > 0 && (
                <Tooltip
                  label={areSubtasksOpen ? t`Hide subtasks` : t`Show subtasks`}
                >
                  <IconButton
                    aria-label={t`Show sub tasks toggle`}
                    colorScheme="grey"
                    icon={<Icon name="git-branch-01" />}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClickShowSubtasks?.();
                    }}
                    variant="ghost"
                  />
                </Tooltip>
              )}
          </UncollapseOnHover>
        )}
      </HStack>

      {content(task)}

      {authStore.isInternalUser ? (
        "commentCount" in task && (
          <TaskCommentsCount
            unreadCommentsCount={task.unreadCommentsCount}
            commentsCount={task.commentCount}
          />
        )
      ) : (
        <TaskCommentsCount commentsCount={task.scopedCommentCount} />
      )}

      {extraContent?.(task)}
    </Grid>
  );
});
