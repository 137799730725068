import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Divider,
  Grid,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
  UseModalProps,
  VStack,
} from "@chakra-ui/react";
import {
  Banner,
  FormRow,
  InputDatePicker,
  IOption,
  ModalConfirm,
  NumberInput,
  RemoveButton,
  Select,
  TextField,
} from "@components/ui-kit";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { t, Trans } from "@lingui/macro";
import { useModalTaskDependenciesQuery } from "@src/__generated__/graphql";
import {
  GetProjectBillableDocument,
  GetProjectBillableQuery,
  GetProjectBillableQueryVariables,
  Task,
} from "@src/__generated__/urql-graphql";
import { getBillingCategoryOptions } from "@src/components/modules/projects/list/Store";
import { Dash } from "@src/components/ui-kit/Dash";
import { Icon } from "@src/components/ui-kit/Icon";
import { TextEditor } from "@src/components/ui-kit/TextEditor/TextEditor";
import { TaskModel } from "@src/components/widgets/Modals/ModalCommunication/models";
import { useAutocompletePositionsFromOurWorkBudgetItem } from "@src/components/widgets/Modals/ModalTask/useAutocompletePositionsFromOurWorkBudgetItem";
import { client } from "@src/services/client";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import {
  fieldToInputProps,
  fieldToSelectProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { FormMode } from "@src/utils/types";
import { TaskSelect } from "@src/widgets/TaskSelect/TaskSelect";
import { UserSelect } from "@src/widgets/UserSelect";
import { isSameDay } from "date-fns";
import { computed, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { DuplicateTaskModal } from "../DuplicateTaskModal";
import { TASK_ID_QUERY_KEY } from "../ModalCommunication/CommunicationModalHeader";
import { Form, TPositionForm } from "./Form";
import { useDependenciesOptions } from "./hooks";

export interface ModalTaskProps extends UseModalProps {
  form: Form;
  mode: FormMode;
  projectId?: string;
  taskId?: Task["id"];
  loading?: boolean;

  projectOptions?: IOption[];
  defaultTaskOptions?: IOption[];
  priorityOptions: IOption[];
  statusOptions: IOption[];

  /**
   * When returns true, modal is closed and reset
   */
  onSubmit: (hasError: boolean) => Promise<boolean>;

  onSubmitLoading?: boolean;
  attachments?: TaskModel["files"];
  alreadyEdited?: boolean;
  refetchTask?: () => void;

  extraBottomContent?: React.ReactNode;
}

export const ModalTask = observer(function ModalTask({
  form,
  mode,
  projectId,
  onSubmit,
  onSubmitLoading,
  loading,
  projectOptions,
  priorityOptions,
  statusOptions,
  attachments,
  taskId,
  alreadyEdited,
  extraBottomContent,
  refetchTask,
  defaultTaskOptions,
  ...modalProps
}: ModalTaskProps) {
  const { taskFormModalStore, workspaceStore, UIStore, authStore } = useStore();
  const [parent] = useAutoAnimate<HTMLTableSectionElement>();
  const confirmModal = useDisclosure();
  const { onCopy } = useClipboard(
    `${location.origin}/notifications?${TASK_ID_QUERY_KEY}=${taskId}`,
  );
  const [parentDependenciesChanged, setParentDependenciesChanged] =
    useState(false);

  const title =
    mode === FormMode.MOVE
      ? t`Move task`
      : mode === FormMode.CREATE
        ? t`New task`
        : mode === FormMode.DUPLICATE
          ? t`Duplicate task`
          : t`Edit task`;
  const submitBtnTitle =
    mode === FormMode.CREATE
      ? t`Create`
      : mode === FormMode.DUPLICATE
        ? t`Duplicate`
        : mode === FormMode.MOVE
          ? t`Move`
          : t`Save`;

  const canChangeEndOrDeadline = computed(() => {
    return workspaceStore.settings?.auto_deadline_in_tasks;
  }).get();

  const deadlineAndEndAreSame = computed(() => {
    if (!form.deadlineDate.$ && !form.durationTo.$) return true;
    return (
      form.durationTo.$ &&
      form.deadlineDate.$ &&
      isSameDay(form.deadlineDate.$, form.durationTo.$)
    );
  }).get();

  const {
    data: dependenciesData,
    loading: loadingDependencies,
    refetch: refetchDependencies,
  } = useModalTaskDependenciesQuery({
    variables: { projectId: (form.projectId.value || projectId)! },
    skip: (!projectId && !form.projectId.value) || !modalProps.isOpen,
  });

  const { budgetItemOptions, workTypeOptions } =
    useDependenciesOptions(dependenciesData);

  const { autocompletePositions } =
    useAutocompletePositionsFromOurWorkBudgetItem(form);

  useEffect(() => {
    if (!projectId && form.budgetItemId.value && mode === FormMode.CREATE) {
      autocompletePositions({
        variables: {
          our_work_budget_item_id: form.budgetItemId.value,
        },
      });
    }
  }, [form.budgetItemId.value]);

  useEffect(() => {
    if (!dependenciesData) return;
    const workTypeIds =
      dependenciesData.projectForTask.availableTimeTrackingWorkTypes.map(
        (type) => type.id,
      );

    for (const position of form.positions.values()) {
      const positionId = position.$.positionId.$;
      if (!positionId) continue;
      if (workTypeIds.includes(positionId)) continue;
      position.$.positionId.onChange(undefined);
    }
  }, [dependenciesData, form.positions]);

  const onSubmitWrapper = async () => {
    const { hasError } = await form.validate();

    if (form.budgetItemId.hasError) {
      document.getElementById("budget-item-select-row")?.scrollIntoView({
        behavior: "smooth",
      });
    }

    if (form.name.hasError) {
      document
        .getElementById("name-form-row")
        ?.scrollIntoView({ behavior: "smooth" });
    }

    if (await onSubmit(hasError)) {
      form.editorRef.current?.clearDraft();
      handleClose();
    }
  };

  const onCloseWrapper = () => {
    if (form.isDirty) {
      confirmModal.onOpen();
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    modalProps.onClose();
    form.reset();
    setParentDependenciesChanged(false);
  };

  const availableWorkTypeOptionsFor = (position: TPositionForm) => {
    if (!form.projectId.value) return form.timeTrackingWorkTypes;
    if (!position.$.userId.value) return workTypeOptions;

    const disabledWorkTypeIds = [...form.positions.values()].flatMap((pos) => {
      const userId = pos.$.userId.value;
      if (!userId || userId !== position.$.userId.value) return [];
      return [pos.$.positionId.value];
    });

    return workTypeOptions.map((i) =>
      disabledWorkTypeIds.includes(i.value) ? { ...i, disabled: true } : i,
    );
  };

  const availableUserOptionsFor = (position: TPositionForm) => {
    if (!position.$.positionId.value) return form.userOptions;
    const disabledUserIds: IOption["value"][] = [];

    for (const pos of form.positions.values()) {
      const workTypeId = pos.$.positionId.value;
      if (!workTypeId || workTypeId !== position.$.positionId.value) continue;
      if (!pos.$.userId.value) continue;
      disabledUserIds.push(pos.$.userId.value);
    }

    return form.userOptions.map((i) =>
      disabledUserIds.includes(i.value) ? { ...i, disabled: true } : i,
    );
  };

  const handleDurationToChange = (durationTo: Date | undefined) => {
    if (
      canChangeEndOrDeadline &&
      deadlineAndEndAreSame &&
      !form.deadlineDate.dirty
    ) {
      form.deadlineDate.value = durationTo;
    }
    form.durationTo.onChange(durationTo);
  };

  const handleDeadlineChange = (deadline: Date | undefined) => {
    if (
      canChangeEndOrDeadline &&
      deadlineAndEndAreSame &&
      !form.durationTo.dirty &&
      deadline
    ) {
      form.durationTo.value = deadline;
    }
    form.deadlineDate.onChange(deadline);
  };

  const onCopyTaskLink = () => {
    onCopy();
    UIStore.toast({
      title: t`Link copied to clipboard`,
      status: "info",
      isClosable: true,
      duration: 3000,
    });
  };

  const handleAfterProjectSelect = async (
    projectId: string | undefined,
    mode: FormMode,
  ) => {
    if (!projectId) return;
    form.budgetItemId.reset();
    form.parent_id.reset();
    refetchDependencies();
    setParentDependenciesChanged(true);

    const { data, error } = await client
      .query<GetProjectBillableQuery, GetProjectBillableQueryVariables>(
        GetProjectBillableDocument,
        {
          projectId: projectId,
        },
      )
      .toPromise();

    if (!data?.projectsSimpleMap || error) return;
    const matchingProject = data.projectsSimpleMap.find(
      ({ id }) => id === projectId,
    );
    if (!matchingProject) return;

    runInAction(() => {
      form.projectBillability = matchingProject.billable;

      if (mode === FormMode.CREATE) {
        form.billable.onChange(matchingProject.billable ? "true" : "false");
      }
    });
  };

  return (
    <Modal
      isCentered
      isOpen={modalProps.isOpen}
      onClose={onCloseWrapper}
      onOverlayClick={onCloseWrapper}
      scrollBehavior="inside"
      size="4xl"
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent maxH="calc(100dvh - 180px)">
        {(loading || (loadingDependencies && mode == FormMode.EDIT)) && (
          <Center
            pos="absolute"
            zIndex={100}
            top="0"
            right="0"
            bottom="0"
            left="0"
            bg="white"
          >
            <Spinner color="teal.500" size="lg" />
          </Center>
        )}
        <ModalHeader>
          <HStack justify="space-between" mr="5">
            <Text>{title}</Text>
            {mode !== FormMode.CREATE && (
              <ButtonGroup colorScheme="grey" variant="ghost">
                <Tooltip label={t`Copy link`}>
                  <IconButton
                    aria-label={t`Copy link`}
                    icon={<Icon name="link-03" w="5" h="5" color="grey.500" />}
                    onClick={onCopyTaskLink}
                  />
                </Tooltip>
                {mode !== FormMode.DUPLICATE && (
                  <Tooltip label={t`Duplicate task`}>
                    <IconButton
                      aria-label={t`Duplicate task`}
                      icon={
                        <Icon name="copy-02" w="5" h="5" color="grey.500" />
                      }
                      onClick={() => {
                        if (!taskId) return;
                        handleClose();
                        UIStore.dialogs.openModal({
                          content: <DuplicateTaskModal taskId={taskId} />,
                        });
                      }}
                    />
                  </Tooltip>
                )}
              </ButtonGroup>
            )}
            <ModalCloseButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            />
          </HStack>
        </ModalHeader>

        {alreadyEdited && (
          <Banner
            status="warning"
            variant="contained"
            borderTop="4px"
            minH="60px"
            borderTopColor="orange.500"
            title={t`Someone has already edited the task`}
            description={t`Copy your changes to paste it after reloading the task.`}
            primaryAction={{
              title: t`Reload task`,
              onClick: () => {
                refetchTask?.();
              },
              leftIcon: <Icon name="repeat-04" />,
            }}
          />
        )}

        <ModalBody>
          <FormRow title={t`Name`} id="name-form-row">
            <TextField
              autoComplete="off"
              {...fieldToInputProps(form.name)}
              autoFocus={mode === FormMode.CREATE}
            />
          </FormRow>

          {!form.taskGroupTemplateId.value && (
            <>
              <FormRow title={t`Project`}>
                <Select
                  asPortal
                  isClearable
                  {...fieldToSelectProps(
                    form.projectId,
                    projectOptions ?? [],
                    (val) => {
                      handleAfterProjectSelect(val, mode);

                      return val;
                    },
                  )}
                />
              </FormRow>
              {form.projectId.value && authStore.isInternalUser && (
                <FormRow title={t`Budget item`} id="budget-item-select-row">
                  <Select
                    asPortal
                    isClearable
                    {...fieldToSelectProps(
                      form.budgetItemId,
                      budgetItemOptions,
                      (val) => {
                        form.parent_id.reset();
                        setParentDependenciesChanged(true);
                        return val;
                      },
                    )}
                  />
                </FormRow>
              )}
            </>
          )}
          <FormRow title={t`Parent task`}>
            <TaskSelect
              options={[]}
              asPortal
              isClearable
              defaultOptions={
                parentDependenciesChanged ? [] : defaultTaskOptions
              }
              parentTasksOnly
              withCreateTask={false}
              value={form.parent_id.value}
              projectFilter={form.projectId.value ? [form.projectId.value] : []}
              budgetItemFilter={
                form.budgetItemId.value ? [form.budgetItemId.value] : []
              }
              onChange={(value) => {
                if (!value) {
                  form.parent_id.reset("");
                  return;
                }

                form.parent_id.onChange(
                  value.length > 0
                    ? Array.isArray(value)
                      ? value[0]
                      : value
                    : "",
                );
              }}
            />
          </FormRow>

          {authStore.isInternalUser && (
            <FormRow
              title={t`Positions / People`}
              style={{ alignItems: "start" }}
              titleClassName="mt-4"
            >
              <div>
                <table>
                  <thead>
                    <Grid
                      as="tr"
                      alignItems="center"
                      columnGap="1rem"
                      templateColumns="22fr 35fr 3fr 35fr 5fr"
                      w="100%"
                      color="#637381"
                      fontSize="xs"
                    >
                      <td>
                        <Trans>Total hrs</Trans>
                      </td>
                      <td>
                        <Trans>Position</Trans>
                      </td>
                      <td></td>
                      <td>
                        <Trans>Person</Trans>
                      </td>
                      <td></td>
                    </Grid>
                  </thead>
                  <tbody className="space-y-4 w-full" ref={parent}>
                    {Array.from(form.positions.entries()).map(
                      ([id, position]) => (
                        <Grid
                          key={id}
                          as="tr"
                          alignItems="start"
                          columnGap="1rem"
                          templateColumns="22fr 35fr 3fr 35fr 5fr"
                        >
                          <td>
                            <NumberInput
                              minValue={0}
                              value={position.$.time.value}
                              onChange={position.$.time.onChange}
                            />
                          </td>
                          <td>
                            <Select
                              asPortal
                              value={position.$.positionId.value}
                              onChange={position.$.positionId.onChange}
                              options={availableWorkTypeOptionsFor(position)}
                              error={position.$.positionId.error}
                            />
                          </td>
                          <td className="text-inkLightest">/</td>
                          <td>
                            <UserSelect
                              asPortal
                              isClearable
                              placeholder={t`Unassigned`}
                              {...fieldToSelectProps(
                                position.$.userId,
                                availableUserOptionsFor(position),
                              )}
                            />
                          </td>
                          <td>
                            {form.positions.size > 1 && (
                              <RemoveButton
                                onClick={() => form.removePosition(id)}
                              />
                            )}
                          </td>
                        </Grid>
                      ),
                    )}
                    <Grid
                      as="tr"
                      alignItems="center"
                      columnGap="1rem"
                      templateColumns="22fr 35fr 3fr 35fr 5fr"
                    >
                      <NumberInput
                        value={form.durationHours}
                        onChange={() => null}
                        disabled
                      />
                      <Box>
                        <Button
                          mt="1"
                          pl="0"
                          color="grey.600"
                          leftIcon={<Icon name="plus" />}
                          onClick={form.addNewPosition}
                          size="sm"
                          variant="plain"
                        >
                          {t`Add`}
                        </Button>
                      </Box>
                    </Grid>
                  </tbody>
                </table>
              </div>
            </FormRow>
          )}

          <FormRow mt="6" title={t`Description`}>
            <Box
              sx={{
                border: "1px solid",
                borderColor: "grey.200",
                rounded: "lg",
                p: "1",
              }}
            >
              <TextEditor
                // INFO: In case some client will want this back
                // draftKey={
                //   !!taskId
                //     ? getTaskDetailDraftKey({
                //         taskId,
                //         editorName: 'description',
                //         // TODO: this is to delete cache from other description instance.
                //         tabId: TaskTabIdEnum.Description,
                //       })
                //     : undefined
                // }
                // enableDrafts={!!taskId}
                attachments={(attachments ?? []).map((file) => ({
                  id: file.public_id,
                  filename: file.filename,
                  mime_type: file.mime_type,
                  viewable: file.urls.viewable!,
                  original: file.urls.original!,
                  thumbnail: file.urls.thumbnail!,
                }))}
                ref={form.editorRef}
                showToolbar
                initialValue={form.description.value.body || undefined}
                ml="2"
                minH="80px"
                isEditable
                onChange={(
                  _,
                  {
                    mentioned_user_ids,
                    mentioned_team_ids,
                    mentioned_assignees,
                  },
                ) => {
                  form.description.onChange({
                    mentioned_user_ids: mentioned_user_ids,
                    mentioned_team_ids: mentioned_team_ids,
                    mentioned_assignees: mentioned_assignees,
                  });
                }}
                placeholder={t`Add a description...`}
              />
            </Box>
          </FormRow>

          <Divider my="4" />

          {extraBottomContent}

          {extraBottomContent && <Divider my="4" />}

          <FormRow title="">
            <WrapComponent
              if={authStore.isInternalUser}
              with={(children) => (
                <Stack
                  direction={{ base: "column", md: "row" }}
                  divider={<StackDivider />}
                  spacing="3"
                >
                  {children}
                </Stack>
              )}
              elseWith={(children) => (
                <HStack align="start" spacing="4">
                  {children}
                </HStack>
              )}
            >
              <Stack w={{ base: "full", md: "60%" }} dir="column" spacing="3">
                {authStore.isInternalUser && (
                  <HStack>
                    <InputDatePicker
                      flex="1"
                      label={t`Duration`}
                      selected={form.durationFrom.value}
                      onChange={(value) =>
                        form.durationFrom.onChange(value?.start)
                      }
                      error={form.durationFrom.error}
                    />
                    <Dash h="8" alignSelf="end" />
                    <InputDatePicker
                      flex="1"
                      label={t`To`}
                      selected={form.durationTo.value}
                      onChange={(value) => {
                        handleDurationToChange(value?.start);
                      }}
                      error={form.durationTo.error}
                    />
                  </HStack>
                )}
                <HStack alignItems="start" gap="2">
                  <Select
                    label={t`Status`}
                    asPortal
                    error={form.status.error}
                    value={form.status.$}
                    options={statusOptions}
                    onChange={form.status.onChange}
                    menuShouldScrollIntoView={false}
                  />
                  {authStore.isInternalUser && (
                    <VStack w="full" spacing="2">
                      <Select
                        label={t`Billing category`}
                        asPortal
                        {...fieldToSelectProps(
                          form.billable,
                          getBillingCategoryOptions(),
                        )}
                        menuShouldScrollIntoView={false}
                      />
                      {form.billabilityChanged &&
                        (mode === FormMode.EDIT || mode === FormMode.MOVE) && (
                          <HStack align="start" spacing="2">
                            <Switch
                              isChecked={form.propagate_billable.value}
                              onChange={({ target }) => {
                                form.propagate_billable.onChange(
                                  target.checked,
                                );
                              }}
                            />
                            <Text color="gray" fontSize="xs">
                              <Trans>
                                Should we change billing category of all
                                existing time entries in this task?
                              </Trans>
                            </Text>
                          </HStack>
                        )}
                    </VStack>
                  )}
                </HStack>
              </Stack>
              <Stack w={{ base: "full", md: "40%" }} dir="column" spacing="3">
                <InputDatePicker
                  label={t`Deadline`}
                  maxW="315px"
                  selected={form.deadlineDate.value}
                  onChange={(val) => {
                    handleDeadlineChange(val?.start);
                  }}
                  error={form.deadlineDate.error}
                />
                <Select
                  label={t`Priority`}
                  error={form.priority.error}
                  asPortal
                  value={form.priority.$}
                  options={priorityOptions}
                  onChange={form.priority.onChange}
                  menuShouldScrollIntoView={false}
                />
              </Stack>
            </WrapComponent>
          </FormRow>
        </ModalBody>

        <ModalConfirm
          destructive
          confirmBtnTitle={t`Discard`}
          isOpen={confirmModal.isOpen}
          onClose={confirmModal.onClose}
          onConfirm={handleClose}
        >
          <Trans>
            Your task will not be saved. Would you like to discard changes?
          </Trans>
        </ModalConfirm>

        <ModalFooter>
          <HStack spacing="3">
            {!taskFormModalStore.modalState.additionalData
              ?.hideCreateAllocationsCheckbox &&
              authStore.isInternalUser && (
                <Checkbox
                  checked={form.create_capacity_allocations.value}
                  onChange={({ target }) =>
                    form.create_capacity_allocations.onChange(target.checked)
                  }
                >
                  <Trans>Create allocation for this task</Trans>
                </Checkbox>
              )}

            <Button colorScheme="grey" onClick={handleClose} variant="outline">
              <Trans>Cancel</Trans>
            </Button>
            <Button
              isDisabled={alreadyEdited}
              isLoading={onSubmitLoading || loadingDependencies}
              onClick={onSubmitWrapper}
            >
              {submitBtnTitle}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
