import { t } from "@lingui/macro";
import {
  SqlOperator,
  TaskDescriptionInput,
  TaskDocument,
  TaskForScopedUsersDocument,
  TaskForScopedUsersQuery,
  TaskForScopedUsersQueryVariables,
  TaskQuery,
  TaskQueryVariables,
  TimeTrackingWorkTypesDocument,
  TimeTrackingWorkTypesQuery,
  TimeTrackingWorkTypesQueryVariables,
  UserScopeEnum,
  UsersSelectOptionsDocument,
  UsersSelectOptionsQuery,
  UsersSelectOptionsQueryVariables,
  UsersWhereColumn,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { TextEditorRef } from "@src/components/ui-kit/TextEditor/TextEditor";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { toApiDate, toApiDateTime } from "@src/utils/dates";
import { ONE_HOUR_IN_SECONDS } from "@src/utils/formatters";
import {
  mustBeAfter,
  mustBeBefore,
  required,
} from "@src/utils/forms/validators";
import mapToOptions from "@src/utils/map-to-options";
import { TaskOption } from "@src/widgets/TaskSelect/TaskSelect";
import { hoursToSeconds } from "date-fns";
import { FieldState, FormState } from "formstate";
import { uniqueId } from "lodash";
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
  toJS,
} from "mobx";
import { createRef } from "react";
import { TaskModel } from "../ModalCommunication/models";

export type TPositionForm = FormState<{
  time: FieldState<string>;
  positionId: FieldState<string | undefined>;
  userId: FieldState<string | undefined>;
}>;

const usersSimpleQueryWhere = new Filters<UsersWhereColumn>([
  new Filter({
    column: UsersWhereColumn.Scope,
    options: [],
    operator: SqlOperator.Eq,
    value: UserScopeEnum.Internal,
  }),
]).asWhereParam;

export class Form {
  mustBeAfterDurationFrom = ($: Date | undefined) => {
    if (!$) return null;
    return mustBeAfter(this.durationFrom, t`duration From`)($);
  };

  mustBeBeforeDurationTo = ($: Date | undefined) => {
    if (!$) return null;
    return mustBeBefore(this.durationTo, t`duration To`)($);
  };

  requiredIfCreateWithCapacityAllocations = (value: any) =>
    this.create_capacity_allocations.$ && required(value);
  mustBeNonBillableIfNonBillableProject = ($: "true" | "false") => {
    if ($ === "true" && this.projectBillability === false) {
      return t`Billable task can't be assigned to non-billable project.`;
    }
    return null;
  };
  requiredIfProjectSelected = ($: string | undefined) => {
    if (this.projectId.value) return required($);
    return null;
  };

  name = new FieldState("").validators(required);
  description = new FieldState<TaskDescriptionInput>({
    body: "",
    mentioned_user_ids: [],
  }).validators(required);

  attachment_ids = new FieldState<string[]>([]);
  projectId = new FieldState<string | undefined>(undefined);
  budgetItemId = new FieldState<string | undefined>(undefined).validators(
    this.requiredIfProjectSelected,
  );
  durationFrom = new FieldState<Date | undefined>(new Date()).validators(
    this.requiredIfCreateWithCapacityAllocations,
    this.mustBeBeforeDurationTo,
  );
  durationTo = new FieldState<Date | undefined>(undefined).validators(
    this.requiredIfCreateWithCapacityAllocations,
    this.mustBeAfterDurationFrom,
  );
  deadlineDate = new FieldState<Date | undefined>(undefined).validators(
    this.mustBeAfterDurationFrom,
    this.mustBeBeforeDurationTo,
  );
  deadlineTime = new FieldState<number | undefined>(undefined);
  priority = new FieldState<string | undefined>(undefined).validators(required);
  status = new FieldState<string | undefined>(undefined).validators(required);
  positions: ObservableMap<string, TPositionForm> = observable.map();
  create_capacity_allocations = new FieldState<boolean>(false);
  billable = new FieldState<"true" | "false">("true").validators(
    this.mustBeNonBillableIfNonBillableProject,
  );
  propagate_billable = new FieldState(true);
  parent_id = new FieldState<string>("");
  editorRef = createRef<TextEditorRef>();
  @observable projectBillability: boolean | undefined = undefined;
  @observable initialBillability?: boolean;
  @observable
  timeTrackingWorkTypes: IOption[] = [];
  @observable userOptions: IOption[] = [];
  @observable task: TaskModel | null = null;
  isTemplate = new FieldState(false);
  taskGroupTemplateId = new FieldState<string | undefined>(undefined);

  @computed get durationHours() {
    let total = 0;
    for (const position of this.positions.values()) {
      total += +position.$.time.value;
    }
    return total.toString();
  }

  get deadlineDateTime(): Date | null {
    if (!this.deadlineDate.value) return null;
    const date = new Date(this.deadlineDate.value);
    date.setHours(0, 0, 0, 0);

    if (!this.deadlineTime.value) return date;
    const h = Math.floor(+this.deadlineTime.value / 3600);
    const m = Math.floor((+this.deadlineTime.value % 3600) / 60);
    date.setHours(h);
    date.setMinutes(m);
    return date;
  }

  taskInternalFetcher = new FetchHelper<TaskQuery, TaskQueryVariables>(
    TaskDocument,
  );
  taskScopedFetcher = new FetchHelper<
    TaskForScopedUsersQuery,
    TaskForScopedUsersQueryVariables
  >(TaskForScopedUsersDocument);

  constructor(private appStore: AppStore) {
    makeObservable(this);
    this.durationFrom.disableAutoValidation();
    this.durationTo.disableAutoValidation();
    this.deadlineDate.disableAutoValidation();

    this.durationFrom.onDidChange(this.validateDates);
    this.durationTo.onDidChange(this.validateDates);
    this.deadlineDate.onDidChange(this.validateDates);

    this.addNewPosition();
    this.fetchTimeTrackingWorkTypes();
    this.fetchUserOptions();
  }

  private _formByUserType = {
    internal: {
      name: this.name,
      description: this.description,
      status: this.status,
      priority: this.priority,
      attachment_ids: this.attachment_ids,
      projectId: this.projectId,
      budgetItemId: this.budgetItemId,
      durationFrom: this.durationFrom,
      durationTo: this.durationTo,
      deadlineDate: this.deadlineDate,
      create_capacity_allocations: this.create_capacity_allocations,
      billable: this.billable,
      positions: this.positions,
      parent_id: this.parent_id,
      isTemplate: this.isTemplate,
      taskGroupTemplateId: this.taskGroupTemplateId,
    },
    client: {
      name: this.name,
      status: this.status,
      priority: this.priority,
      description: this.description,
      attachment_ids: this.attachment_ids,
      projectId: this.projectId,
      deadlineDate: this.deadlineDate,
      parent_id: this.parent_id,
    },
    partner: {
      name: this.name,
      status: this.status,
      priority: this.priority,
      description: this.description,
      attachment_ids: this.attachment_ids,
      projectId: this.projectId,
      deadlineDate: this.deadlineDate,
      parent_id: this.parent_id,
    },
  };

  @computed get form() {
    return this._formByUserType[this.appStore.authStore.user!.type];
  }

  @action hasAccessToField(
    field:
      | keyof (typeof this._formByUserType)["internal"]
      | keyof (typeof this._formByUserType)["client"]
      | keyof (typeof this._formByUserType)["partner"],
  ) {
    return Object(
      toJS(this._formByUserType[this.appStore.authStore.user!.type]),
    ).hasOwnProperty(field);
  }

  @computed get isTaskFetching(): boolean {
    return (
      this.taskInternalFetcher.isLoading.value ||
      this.taskScopedFetcher.isLoading.value
    );
  }

  @computed get fieldsToCheckIfDirty() {
    return [
      this.name,
      this.description,
      this.attachment_ids,
      this.projectId,
      this.budgetItemId,
      this.durationFrom,
      this.durationTo,
      this.deadlineDate,
      this.create_capacity_allocations,
      this.billable,
    ];
  }

  @computed get billabilityChanged() {
    if (!this.initialBillability) return false;
    const billable = this.billable.value === "true";
    return this.initialBillability !== billable;
  }

  @action.bound fillTaskForm(task: TaskModel) {
    this.name.onChange(task.name);
    this.projectId.onChange(task.ourWorkBudgetItem?.project.id);
    this.budgetItemId.onChange(task.ourWorkBudgetItem?.id);
    this.durationFrom.onChange(task.from ? new Date(task.from) : undefined);
    this.durationTo.onChange(task.to ? new Date(task.to) : undefined);
    this.priority.onChange(task.priority.id);
    this.status.onChange(task.status.id);
    this.billable.onChange(task.billable ? "true" : "false");
    if (task.description) {
      this.description.onChange({
        body: task.description.body,
        mentioned_user_ids: task.description.mentioned_user_ids,
      });
    }
    this.attachment_ids.onChange(task.files.map((f) => f.public_id));
    if (task.deadlineDate) {
      this.setDeadlineDateTime(new Date(task.deadlineDate));
    }
    this.positions.clear();
    if (task.positions.length) {
      task.positions.map((position) => {
        this.addPosition(
          position.planned_time ?? 0,
          position.timeTrackingWorkType?.id,
          position.user?.id,
          position.id,
        );
      });
    } else {
      this.addPosition(0);
    }

    this.parent_id.onChange(task.parent?.id ?? "");
    this.isTemplate.onChange(task.is_template);
    this.taskGroupTemplateId.onChange(task.task_template_group_id ?? undefined);

    runInAction(() => {
      this.fieldsToCheckIfDirty.forEach((field) => {
        field.dirty = undefined;
      });
    });
  }

  @action.bound setDeadlineDateTime(date: Date) {
    this.deadlineDate.onChange(date);

    this.deadlineTime.onChange(
      Math.floor(
        date.getHours() * ONE_HOUR_IN_SECONDS +
          date.getMinutes() * 60 +
          date.getSeconds(),
      ),
    );
  }

  @action.bound addPosition(
    timeInSec: number,
    positionId?: string,
    userId?: string,
    entryId?: string,
  ) {
    this.positions.set(
      entryId || uniqueId("position"),
      new FormState({
        time: new FieldState((timeInSec / 3600).toFixed(1)),
        positionId: new FieldState(positionId).validators(required),
        userId: new FieldState(userId).validators(
          this.requiredIfCreateWithCapacityAllocations,
        ),
      }),
    );
  }

  @action.bound addNewPosition() {
    this.addPosition(0);
  }

  @action.bound removePosition(id: string): void {
    this.positions.delete(id);
    if (this.positions.size === 0) {
      this.addNewPosition();
    }
  }

  async validate() {
    return this.appStore.authStore.isInternalUser
      ? await new FormState([
          this.name,
          this.budgetItemId,
          this.durationFrom,
          this.durationTo,
          this.deadlineDate,
          this.deadlineTime,
          this.priority,
          this.status,
          this.billable,
          ...this.positions.values(),
        ]).validate()
      : await new FormState([
          this.name,
          this.projectId,
          this.description,
          this.attachment_ids,
          this.priority,
          this.status,
        ]).validate();
  }

  @action.bound serialize() {
    const userType = this.appStore.authStore.user!.type;
    const uniqueFiles = new Set(
      this.editorRef.current?.getAttachments()?.map(({ id }) => id),
    );
    const parentId = this.parent_id.$ !== "" ? this.parent_id.$ : null;
    const scopedTask = {
      name: this.name.$,
      description: {
        body: this.editorRef.current?.getContent() ?? "",
        mentioned_user_ids: this.description.$.mentioned_user_ids,
        mentioned_team_ids: this.description.$.mentioned_team_ids,
        mentioned_assignees: this.description.$.mentioned_assignees,
      },
      project_id: this.projectId.$,
      files: Array.from(uniqueFiles),
      task_priority_id: this.priority.$,
      task_status_id: this.status.$,
      deadline: this.deadlineDateTime ? toApiDate(this.deadlineDateTime) : null,
      parent_id: parentId,
      is_template: this.isTemplate.$,
    };
    return {
      internal: {
        name: this.name.$,
        description: {
          body: this.editorRef.current?.getContent() ?? "",
          mentioned_user_ids: this.description.$.mentioned_user_ids,
          mentioned_team_ids: this.description.$.mentioned_team_ids,
          mentioned_assignees: this.description.$.mentioned_assignees,
        },
        files: Array.from(uniqueFiles),
        our_work_budget_item_id: this.budgetItemId.$!,
        task_priority_id: this.priority.$,
        task_status_id: this.status.$,
        from: this.durationFrom.$ ? toApiDate(this.durationFrom.$) : null,
        to: this.durationTo.$ ? toApiDate(this.durationTo.$) : null,
        deadline: this.deadlineDateTime
          ? toApiDateTime(this.deadlineDateTime)
          : null,
        create_capacity_allocations: this.create_capacity_allocations.$,
        billable: this.billable.$ === "true" ? true : false,
        positions: [...this.positions.values()].map((pos) => ({
          user_id: pos.$.userId.$,
          time_tracking_work_type_id: pos.$.positionId.$!,
          planned_time: hoursToSeconds(Number(pos.$.time.$)),
        })),
        parent_id: parentId,
        is_template: this.isTemplate.$,
        task_template_group_id: this.taskGroupTemplateId.$ ?? null,
      },
      client: scopedTask,
      partner: scopedTask,
    }[userType];
  }

  @action.bound reset() {
    new FormState([
      this.name,
      this.attachment_ids,
      this.description,
      this.budgetItemId,
      this.durationFrom,
      this.durationTo,
      this.deadlineDate,
      this.deadlineTime,
    ]).reset();
    this.projectBillability = undefined;
    this.positions.clear();
    this.addNewPosition();
  }

  @action.bound validateDates() {
    this.durationFrom.validate();
    this.durationTo.validate();
    this.deadlineDate.validate();
  }

  @computed get isDirty(): boolean {
    const defaultWorkspaceStatus =
      this.status.$ ===
      this.appStore.workspaceStore.settings?.default_task_status_id.toString();
    const defaultWorkspacePriority =
      this.priority.$ ===
      this.appStore.workspaceStore.settings?.default_task_priority_id.toString();

    return (
      this.fieldsToCheckIfDirty.some((field) => !!field.dirty) ||
      !defaultWorkspaceStatus ||
      !defaultWorkspacePriority
    );
  }

  @action async fetchTimeTrackingWorkTypes() {
    const { data, error } = await client.query<
      TimeTrackingWorkTypesQuery,
      TimeTrackingWorkTypesQueryVariables
    >({
      query: TimeTrackingWorkTypesDocument,
    });

    if (!data || error) return;
    this.timeTrackingWorkTypes = data.timeTrackingWorkTypes.map((workType) => ({
      value: workType.id,
      label: workType.title,
    }));
  }

  @action async fetchUserOptions() {
    const { data, error } = await client.query<
      UsersSelectOptionsQuery,
      UsersSelectOptionsQueryVariables
    >({
      query: UsersSelectOptionsDocument,
      variables: {
        where: usersSimpleQueryWhere,
      },
    });

    if (!data?.userSimpleMap || error) return;
    this.userOptions = mapToOptions.users(data.userSimpleMap);
  }

  @action.bound setTaskFromQuery(query: TaskQuery | TaskForScopedUsersQuery) {
    this.task = new TaskModel(
      query.task,
      "taskPositionStat" in query ? query.taskPositionStat : undefined,
    );
    this.editorRef.current?.setContent(query.task.description?.body ?? "");
    this.initialBillability =
      "billable" in query.task ? query.task.billable : undefined;

    this.fillTaskForm(this.task);
  }

  @action.bound async fetchTaskInternal(taskId: string) {
    this.task = null;
    const [data, error] = await this.taskInternalFetcher.fetch({
      id: taskId,
    });

    if (error) return;
    this.setTaskFromQuery(data);
  }

  @action.bound async fetchTaskScoped(taskId: string) {
    this.task = null;
    const [data, error] = await this.taskScopedFetcher.fetch({ id: taskId });

    if (error) return;

    this.setTaskFromQuery(data);
  }

  async fetchTask(taskId: string) {
    this.appStore.authStore.isInternalUser
      ? this.fetchTaskInternal(taskId)
      : this.fetchTaskScoped(taskId);
  }

  @action.bound handleTaskSelect(
    value: TaskOption["value"] | TaskOption["value"][],
    data: Partial<TaskOption> | Partial<TaskOption>[],
  ) {
    if (!value) {
      this.parent_id.reset("");
      return;
    }

    this.parent_id.onChange(
      value.length > 0 ? (Array.isArray(value) ? value[0] : value) : "",
    );

    const extraInfo = Array.isArray(data) ? data?.[0] : data;
    if (!extraInfo) return;
    this.projectId.onChange(extraInfo.projectId);
    this.budgetItemId.onChange(extraInfo.budgetItemId);
    this.billable.onChange(extraInfo.billable ? "true" : "false");
  }
}
